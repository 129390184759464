<template>
  <div style="height: 100%;">
    <div class="biao_ti" v-show="!pleshow">
      <van-icon @click="back" class="fanhui" name="arrow-left" />
      <span style="vertical-align: middle;">
        <span style="font-weight: bold;">爱车信息</span>
        <span v-if="auth == 1" style="color: red;">（待认证）</span>
        <span v-if="auth == 2" style="color: #02f78e;">（认证通过）</span>
        <span v-if="auth == 3" style="color: red;">（认证未通过）</span>
      </span>
    </div>
    <div style="padding:46px 0px;position: relative;" v-show="!pleshow">
      <van-cell-group>
        <van-field
          v-model="addForm.carNo"
          ref="child"
          clearable
          required
          label="车牌号码"
          placeholder="请输入"
          :readonly="ruanjianpan"
          @click="getple"
        >
          <!-- :class="addForm.carNoAuth?'dd':'cc'"
          :right-icon="auth !=1 ||auth != 0?addForm.carNoAuth == 0 ?'clear':'checked':''"-->
          <template #button>
            <van-icon class="dd" v-if="addForm.carNo.length >6" name="checked" />
          </template>
        </van-field>
        <van-field required v-model="addForm.name" clearable label="姓名" placeholder="请输入">
          <template #button>
            <van-icon class="dd" v-if="addForm.name.length >= 2" name="checked" />
          </template>
        </van-field>
        <!-- @blur="phjiaoyan" -->
        <van-field
          v-model="addForm.phone"
          required
          clearable
          label="手机号"
          type="tel"
          placeholder="请输入您的手机号码"
        >
          <template #button>
            <van-icon class="dd" v-if="/^1[3456789]\d{9}$/.test(addForm.phone)" name="checked" />
          </template>
        </van-field>
      </van-cell-group>
      <div class="cgenduo" v-show="!gdisok">
        <span style="vertical-align: middle" @click="gdisok = !gdisok">更多</span>
        <van-icon style="vertical-align: middle" name="arrow-down" />
      </div>
      <div class="genduo" v-show="gdisok">
        <van-field
          v-model="chexin"
          clearable
          required
          label="车型"
          placeholder="请选择"
          @click="tochexin"
          readonly
        >
          <template #button>
            <van-icon class="dd" v-if="chexin" name="checked" />
          </template>
        </van-field>
        <van-field v-model="addForm.carVin" clearable label="车架号" placeholder="选填">
          <!-- <template #button>
          <van-button size="mini" plain type="primary" @click.stop="pipei">匹配车型</van-button>
          <van-icon class="dd" v-if="addForm.carVin.length == 17" name="checked" />
          </template>-->
        </van-field>
        <van-field readonly required clearable label="行驶证照片">
          <template #button>
            <div
              class="cheshibie"
              v-if="!(addForm.name.length >= 2 && /^1[3456789]\d{9}$/.test(addForm.phone) && addForm.carNo.length >6 && chexin && addForm.carVin.length == 17 &&  addForm.driveUrl)"
            >
              <span v-if="modify == 1">车架号识别有误，请人工核对!</span>
            </div>
          </template>
        </van-field>
        <div class="tupian" @click="shangchuan">
          <img
            class="shanchu"
            @click="shanchuimg"
            v-if="addForm.driveUrl && auth != 2"
            src="../../assets/img/guan2.png"
            alt
          />
          <img class="cheimgs" v-if="addForm.driveUrl" :src="addForm.driveUrl" alt />

          <!-- afterRead -->
          <div v-else>
            <!-- <img class="img_dw" v-if="!phisok" src="../../assets/img/xsz.png" alt /> -->
            <van-uploader :after-read="onRead">
              <img class="shang" src="../../assets/img/xsz.png" alt />
            </van-uploader>
          </div>
        </div>
        <div class="zidong">*请上传行驶证照片后核对以下自动填写的信息*</div>
      </div>
      <div class="bott" v-if="!pleshow">
        <van-button class="bott_cd" round @click="ctijiao" v-preventReClick type="primary">
          <span v-if="auth == 0">提交</span>
          <span v-if="auth == 1">提交</span>
          <span v-if="auth == 3 ||auth == 2">重新提交</span>
        </van-button>
        <!-- <div class="bott_c" @click="tianjia" v-if="auth != 2" v-preventReClick>
          <span v-if="auth == 0">提交</span>
          <span v-if="auth == 1">提交</span>
          <span v-if="auth == 3">重新提交</span>
        </div>-->
      </div>
    </div>
    <van-dialog
      v-model="show"
      title="提 示"
      show-cancel-button
      confirm-button-text="不更换并提交"
      cancel-button-text="更换并提交"
      @confirm="Nogenhuan"
      @cancel="genhuan"
    >
      <div class="tishi">
        您的爱车{{lis.carNo}}已注册了手机号码【{{lis.oldPhone}}】，需要更换现在的号码
        <span
          style="color: red;"
        >【{{lis.newPhone}}】</span>吗?
      </div>
    </van-dialog>
    <!-- <van-dialog
      v-model="carshow"
      title="提 示"
      show-cancel-button
      confirm-button-text="去添加"
      cancel-button-text="取消"
      @confirm="tochexin"
      @cancel="carquxiao"
    >
      <div class="tishi">尊敬的客户：非常抱歉！系统未能成功识别您的车型，您需要手工添加车型才能完成注册。</div>
    </van-dialog>-->
    <van-dialog
      v-model="shenhe"
      title="提 示"
      show-cancel-button
      confirm-button-text="继续提交"
      cancel-button-text="我要核对信息"
      @confirm="tijiaoshenhe"
      @cancel="heduishenhe"
    >
      <div
        class="tishi"
        style="color:#666"
      >尊敬的客户：系统识别的车架号（或车牌号）有误，请您对照行驶证核对信息再提交，如果问题仍然存在，请点击"继续提交"。我们将在1个工作日内为您解决问题，谢谢！</div>
    </van-dialog>
    <van-popup v-model="jiangquan" :close-on-click-overlay="false">
      <img src="../../assets/img/dai.png" @click="tulinqu" class="dai" alt />
      <van-icon @click.stop="quanguanbi" class="gbiquan" name="close" />
    </van-popup>
    <!-- <van-popup v-model="kaixin" :close-on-click-overlay="false">
      <div class="kaixin_a">
        <div class="hhh1">领取免费券成功！</div>
        <div class="xixi">
          <img class="mianfei" src="../../assets/img/mianfei.png" alt /> x3
        </div>
        <van-button round size="small" class="shouxia" type="danger" @click="shouxia">开心收下</van-button>
        <van-icon class="xx" @click="shouxia" name="cross" />
      </div>
    </van-popup>-->
    <carPlate ref="ple" v-show="pleshow" :plate_number="addForm.carNo" @getPlate="getPlate"></carPlate>
    <!-- <input type="text" v-show="pleshow" ref="child" v-model="addForm.carNo"> -->
    <div class="manual" v-show="pleshow" @click="searchIn">切换手机键盘</div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import orderModel from "../../api/order";
import utils from "../../assets/utils";
import preventReClick from "../../assets/preventReClick";
import carPlate from "../../components/carplate.vue";
import { ImagePreview } from "vant";
import Exif from "exif-js";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  // mixins: [preventBack], //注入
  components: {
    carPlate,
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  data() {
    return {
      carshow: false,
      gdisok: false,
      loading: false,
      ruanjianpan: true, //动态控制软键盘
      finished: true,
      shenhe: false,
      kaixin: false,
      phisok: false,
      jiangquan: false,
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      zcisok: this.$route.query.zcisok, //判断是否推送消息进来得
      chexin: "",
      isok: false,
      show: false,
      auth: 0,
      id: "",
      addForm: {
        driveUrl: "",
        carVin: "",
        name: "",
        carNo: ""
      },
      pleshow: false,
      change: 0,
      list: [],
      lis: {},
      modify: 0,
      haveChange: 0, //是否用当前号码覆盖原号码 0:不是 1:是
      haveAdopt: 0, //是否直接通过 0:不是 1:是
      carBrandLogo: "", //车logo
      files: {
        name: "",
        type: ""
      },
      headerImage: null,
      picValue: null,
      upImgUrl: ""
      // ccdd: ""
    };
  },

  methods: {
    baocuo(e) {
      var err = "userID=" + this.userInfo.customerId + "/捕获的错误" + e;
      userModel
        .qderror({ error: err })
        .then(res => {
          console.log(res);
        })
        .catch();
    },
    searchIn() {
      this.ruanjianpan = false;
      this.pleshow = false;
      console.log(this.$refs.child);
      // this.ruanjianpan = false

      this.$nextTick(function() {
        //DOM 更新了
        this.$refs.child.focus();
      });
    },
    //领取获取customerId
    tulinqu() {
      var _this = this;
      // const data = {
      //   gid: this.shangjia.id,
      //   uid: this.userInfo.id
      //   // uid: 1775
      // };
      // orderModel
      //   .count(data)
      //   .then(e => {
      //     _this.userInfo.customerId = e.data.customerId;
      //     localStorage.setItem("userInfo", JSON.stringify(_this.userInfo));
      //     _this.zhijielunqu();
      //   })
      //   .catch();
      sessionStorage.setItem("isok3", "true");
      if (_this.zcisok == "true") {
        _this.$router.push({
          path: "user"
        });
      } else {
        _this.$router.go(-1);
      }
    },
    //开心收下
    // shouxia() {
    //   this.kaixin = false;
    //   setTimeout(() => {
    //     this.$router.go(-1);
    //     sessionStorage.setItem("isok3", "true");
    //   }, 800);
    // },
    //用customerId领取全部券
    zhijielunqu() {
      userModel
        .register({ customerId: this.userInfo.customerId })
        .then(e => {
          this.jiangquan = false;
          this.kaixin = true;
        })
        .catch();
    },
    //关闭弹窗
    quanguanbi() {
      var _this = this;
      this.jiangquan = false;
      setTimeout(() => {
        sessionStorage.setItem("isok3", "true");
        if (_this.zcisok == "true") {
          _this.$router.push({
            path: "user"
          });
        } else {
          _this.$router.go(-1);
        }

        // this.$router.push({
        //   path: "user"
        // });
        // window.history.go(-1);
      }, 800);
    },
    phjiaoyan() {
      var _this = this;
      if (_this.addForm.phone == "" || !_this.addForm.phone) {
        // _this.$toast("请先输入手机号码");
        this.phisok = false;
        return;
      } else if (!/^1[3456789]\d{9}$/.test(_this.addForm.phone)) {
        // _this.$toast("手机号码格式错误");
        this.phisok = false;
        return;
      }
      this.phisok = true;
    },
    shangchuan() {
      var _this = this;
      // if (_this.addForm.phone == "" || !_this.addForm.phone) {
      //   _this.$toast("请先输入手机号码");
      //   return;
      // } else if (!/^1[3456789]\d{9}$/.test(_this.addForm.phone)) {
      //   _this.$toast("手机号码格式错误");
      //   return;
      // }
    },
    // //上传前拦截
    // beforeRead(file) {
    //   var _this = this;
    //   if (_this.addForm.phone == "" || !_this.addForm.phone) {
    //     _this.$toast("请先输入手机号码");
    //     return;
    //   } else if (!/^1[3456789]\d{9}$/.test(_this.addForm.phone)) {
    //     _this.$toast("手机号码格式错误");
    //     return;
    //   }
    // },
    // 组件方法 获取 流
    async onRead(file) {
      var _this = this;
      // if (_this.addForm.phone == "" || !_this.addForm.phone) {
      //   _this.$toast("请先输入手机号码");
      //   return;
      // } else if (!/^1[3456789]\d{9}$/.test(_this.addForm.phone)) {
      //   _this.$toast("手机号码格式错误");
      //   return;
      // }
      // console.log(file);
      // console.log(file.file);
      this.files.name = file.file.name; // 获取文件名
      this.files.type = file.file.type; // 获取类型
      this.picValue = file.file; // 文件流
      this.imgPreview(this.picValue);
    },
    // 处理图片
    imgPreview(file) {
      let self = this;
      try {
        // 你的逻辑代码
        let Orientation;
        //去获取拍照时的信息，解决拍出来的照片旋转问题
        Exif.getData(file, function() {
          Orientation = Exif.getTag(this, "Orientation");
        });
        // 看支持不支持FileReader
        if (!file || !window.FileReader) return;
        if (/^image/.test(file.type)) {
          // 创建一个reader
          let reader = new FileReader();
          // 将图片2将转成 base64 格式
          reader.readAsDataURL(file);
          // 读取成功后的回调
          reader.onloadend = function() {
            // console.log(this.result);
            let result = this.result;
            let img = new Image();
            img.src = result;
            //判断图片是否大于500K,是就直接上传，反之压缩图片
            if (this.result.length <= 500 * 1024) {
              self.headerImage = this.result;
              self.postImg();
            } else {
              img.onload = function() {
                let data = self.compress(img, Orientation);
                self.headerImage = data;
                self.postImg();
              };
            }
          };
        }
      } catch (e) {
        // 错误处理代码片段
        console.log(e);
        self.baocuo(e);
      }
    },
    // 压缩图片
    compress(img, Orientation) {
      try {
        // 你的逻辑代码
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        //瓦片canvas
        let tCanvas = document.createElement("canvas");
        let tctx = tCanvas.getContext("2d");
        // let initSize = img.src.length;
        let width = img.width;
        let height = img.height;
        //如果图片大于四百万像素，计算压缩比并将大小压至400万以下
        let ratio;
        if ((ratio = (width * height) / 2000000) > 1) {
          // console.log("大于400万像素");
          ratio = Math.sqrt(ratio);
          width /= ratio;
          height /= ratio;
        } else {
          ratio = 1;
        }
        canvas.width = width;
        canvas.height = height;
        //    铺底色
        ctx.fillStyle = "#fff";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        //如果图片像素大于100万则使用瓦片绘制
        let count;
        if ((count = (width * height) / 1000000) > 1) {
          // console.log("超过100W像素");
          count = ~~(Math.sqrt(count) + 1); //计算要分成多少块瓦片
          //      计算每块瓦片的宽和高
          let nw = ~~(width / count);
          let nh = ~~(height / count);
          tCanvas.width = nw;
          tCanvas.height = nh;
          for (let i = 0; i < count; i++) {
            for (let j = 0; j < count; j++) {
              tctx.drawImage(
                img,
                i * nw * ratio,
                j * nh * ratio,
                nw * ratio,
                nh * ratio,
                0,
                0,
                nw,
                nh
              );
              ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
            }
          }
        } else {
          ctx.drawImage(img, 0, 0, width, height);
        }
        //修复ios上传图片的时候 被旋转的问题
        if (Orientation != "" && Orientation != 1) {
          switch (Orientation) {
            case 6: //需要顺时针（向左）90度旋转
              this.rotateImg(img, "left", canvas);
              break;
            case 8: //需要逆时针（向右）90度旋转
              this.rotateImg(img, "right", canvas);
              break;
            case 3: //需要180度旋转
              this.rotateImg(img, "right", canvas); //转两次
              this.rotateImg(img, "right", canvas);
              break;
          }
        }
        //进行最小压缩
        let ndata = canvas.toDataURL("image/jpeg", 0.1);
        tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
        return ndata;
      } catch (e) {
        // 错误处理代码片段

        console.log(e);
        this.baocuo(e);
      }
    },
    // 旋转图片
    rotateImg(img, direction, canvas) {
      try {
        // 你的逻辑代码
        //最小与最大旋转方向，图片旋转4次后回到原方向
        const min_step = 0;
        const max_step = 3;
        if (img == null) return;
        //img的高度和宽度不能在img元素隐藏后获取，否则会出错
        let height = img.height;
        let width = img.width;
        let step = 2;
        if (step == null) {
          step = min_step;
        }
        if (direction == "right") {
          step++;
          //旋转到原位置，即超过最大值
          step > max_step && (step = min_step);
        } else {
          step--;
          step < min_step && (step = max_step);
        }
        //旋转角度以弧度值为参数
        let degree = (step * 90 * Math.PI) / 180;
        let ctx = canvas.getContext("2d");
        switch (step) {
          case 0:
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0);
            break;
          case 1:
            canvas.width = height;
            canvas.height = width;
            ctx.rotate(degree);
            ctx.drawImage(img, 0, -height);
            break;
          case 2:
            canvas.width = width;
            canvas.height = height;
            ctx.rotate(degree);
            ctx.drawImage(img, -width, -height);
            break;
          case 3:
            canvas.width = height;
            canvas.height = width;
            ctx.rotate(degree);
            ctx.drawImage(img, -width, 0);
            break;
        }
      } catch (e) {
        // 错误处理代码片段
        console.log(e);
        this.baocuo(e);
      }
    },
    //将base64转换为文件
    dataURLtoFile(dataurl) {
      try {
        // 你的逻辑代码
        var arr = dataurl.split(","),
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], this.files.name, {
          type: this.files.type
        });
      } catch (e) {
        // 错误处理代码片段
        console.log(e);
        this.baocuo(e);
      }
    },
    //这里写接口
    async postImg() {
      var _this = this;
      try {
        // 你的逻辑代码
        let file = this.dataURLtoFile(this.headerImage);
        let formData = new window.FormData();
        formData.append("file", file);
        console.log(file);
        userModel
          .uploadPicturexin(formData)
          .then(e => {
            _this.addForm.driveUrl = e.data.driveUrl;
            // this.modify = e.data.modify;
            if (
              e.data.carBrand == "" ||
              e.data.carBrand == null ||
              e.data.carBrand == "null"
            ) {
              // _this.shenhe = true;
              // console.log("车型识别不成功！");
            } else {
              _this.chexin = e.data.carBrand + "-" + e.data.carModel;
            }
            if (e.data.modify != 2) {
              _this.carBrandLogo = e.data.logo;
              _this.addForm.driveUrl = e.data.driveUrl;
              _this.addForm.carNo = e.data.carNo;
              _this.addForm.name = e.data.owner;
              _this.addForm.carVin = e.data.vin;
              if (
                e.data.carBrand == "" ||
                e.data.carBrand == null ||
                e.data.carBrand == "null"
              ) {
                // _this.shenhe = true;
                // console.log("车型识别不成功！");
              } else {
                _this.chexin = e.data.carBrand + "-" + e.data.carModel;
              }
              // _this.chexin = e.data.carBrand + "-" + e.data.carModel;
            }

            if (e.data.modify == 0) {
              _this.haveAdopt = 1;
              // _this.haomayanzhen();
            }
            // else if (e.data.modify == 1) {
            //   _this.shenhe = true;
            // } else if (e.data.modify == 2) {
            //   _this.addForm.driveUrl = e.data.driveUrl;
            //   _this.$toast("您提交的行驶证照片有误，请重新上传！");
            // }
          })
          .catch();
      } catch (e) {
        // 错误处理代码片段
        console.log(e);
        this.baocuo(e);
      }
    },

    //////////////////////////////////////////
    tochexin() {
      this.carshow = false;
      this.$router.push({
        path: "model_list"
      });
    },
    pipei() {
      if (this.addForm.carVin == "") {
        this.$toast("请先输入车架号");
        return;
      }
      userModel
        .vincarModel({ vinCode: this.addForm.carVin })
        .then(e => {
          this.$toast("识别成功");
          this.carBrandLogo = e.data.logo;
          // this.addForm.year = e.data.year;
          // this.addForm.combineName = e.data.combineName;
          if (e.data.carBrand != "") {
            this.chexin = e.data.carBrand + "-" + e.data.carModel;
          }
        })
        .catch();
    },
    shanchuimg() {
      this.addForm.driveUrl = "";
    },

    // dataURLtoFile(dataurl, filename) {
    //   // 将base64转换为file文件
    //   let arr = dataurl.split(",");
    //   let mime = arr[0].match(/:(.*?);/)[1];
    //   let bstr = atob(arr[1]);
    //   let n = bstr.length;
    //   let u8arr = new Uint8Array(n);
    //   while (n--) {
    //     u8arr[n] = bstr.charCodeAt(n);
    //   }
    //   return new File([u8arr], filename, { type: mime });
    // },
    //不更换提交
    Nogenhuan() {
      this.haveChange = 0;
      this.addForm.phone = this.lis.oldPhone;
      this.ctijiao();
    },

    //取消去跟换车型
    carquxiao() {
      this.carshow = false;
    },
    //更换
    genhuan() {
      console.log("更换");
      this.haveChange = 1;
      this.ctijiao();
    },
    //核对审核
    heduishenhe() {
      console.log("核对审核");
      this.shenhe = false;
    },

    //提交审核
    tijiaoshenhe() {
      this.haveAdopt = 0;
      this.haomayanzhen();
      console.log("提交审核");
    },
    //打开键盘
    getple() {
      this.pleshow = true;
      this.$refs.ple.clickShowKeyboard();
    },
    //有号码验证的处理
    tianjia() {
      var _this = this;
      try {
        // 你的逻辑代码
        if (_this.addForm.phone == "" || !_this.addForm.phone) {
          _this.$toast("请输入手机号码");
          return;
        } else if (!/^1[3456789]\d{9}$/.test(_this.addForm.phone)) {
          _this.$toast("手机号码格式错误");
          return;
        } else if (_this.addForm.name == "" || !_this.addForm.name) {
          _this.$toast("请输入您的姓名");
          return;
        } else if (_this.addForm.carNo.length < 3) {
          _this.$toast("请填写车牌号码");
          return;
        }
        //  else if (_this.chexin == "" || !_this.chexin) {
        //   _this.$toast("请先填写车型");
        //   return;
        // }
        // if (this.addForm.carVin.length < 17) {
        //   _this.$toast("请输入17位车架号");
        //   return;
        // }
        // if (this.addForm.driveUrl == "") {
        //   _this.$toast("请先上传行驶证照片");
        //   return;
        // }
        this.haomayanzhen();
      } catch (e) {
        // 错误处理代码片段
        console.log(e);
        this.baocuo(e);
      }
    },
    haomayanzhen() {
      try {
        // 你的逻辑代码
        var lst = {
          carNo: this.addForm.carNo,
          phone: this.addForm.phone,
          gid: this.shangjia.id
        };

        userModel
          .yanzhencarNo(lst)
          .then(e => {
            if (e.data.oldPhone == null) {
              this.ctijiao();
            } else {
              this.show = true;
              this.lis = e.data;
            }
          })
          .catch();
      } catch (e) {
        // 错误处理代码片段
        console.log(e);
        this.baocuo(e);
      }
    },
    ctijiao() {
      var _this = this;
      try {
        // 你的逻辑代码
        if (_this.addForm.name == "" || !_this.addForm.name) {
          _this.$toast("请输入您的姓名");
          return;
        }
        if (!/^1[3456789]\d{9}$/.test(_this.addForm.phone)) {
          _this.$toast("手机号码格式错误");
          return;
        } else if (_this.addForm.phone == "" || !_this.addForm.phone) {
          _this.$toast("请输入手机号码");
          return;
        } else if (_this.addForm.carNo.length < 3) {
          _this.$toast("请填写车牌号码");
          return;
        }
        // else if (_this.chexin == "" || !_this.chexin) {
        //   _this.$toast("请先填写车型");
        //   return;
        // }
        if (_this.chexin == "" || !_this.chexin) {
          var isok = false;
        } else {
          var isok = true;
          var chexin = this.chexin.split("-");
        }
        // if (this.addForm.carVin.length < 17) {
        //   _this.$toast("请输入17位车架号");
        //   return;
        // }
        // if (this.addForm.driveUrl == "") {
        //   _this.$toast("请先上传行驶证照片");
        //   return;
        // }

        const data = {
          carNo: this.addForm.carNo,
          carBrandLogo: this.carBrandLogo,
          driveUrl: this.addForm.driveUrl,
          carBrand: isok == false ? "" : chexin[0],
          carModel: isok == false ? "" : chexin[0],
          id: this.id,
          gid: this.shangjia.id,
          mallUserId: this.userInfo.id,
          // mallUserId: 1775,
          carVin: this.addForm.carVin,
          name: this.addForm.name,
          phone: this.addForm.phone,
          haveChange: this.haveChange,
          // haveAdopt: this.haveAdopt,
          haveAdopt: 1,
          onDefault: true //是否默认车辆
        };

        userModel
          .carInfochexin(data)
          .then(e => {
            // loading.clear();
            sessionStorage.setItem("chexin", JSON.stringify(e.data));
            _this.userInfo.phone = _this.addForm.phone;
            localStorage.setItem("userInfo", JSON.stringify(_this.userInfo));
            _this.$toast("提交成功");
            setTimeout(() => {
              if (e.data.isFirst == true) {
                if (this.shangjia.id == 208 || this.shangjia.id == 484) {
                  _this.jiangquan = true;
                } else {
                  setTimeout(() => {
                    _this.$router.go(-1);
                  }, 1000);
                }
              } else {
                if (_this.zcisok == "true") {
                  _this.$router.push({
                    path: "user"
                  });
                } else {
                  _this.$router.go(-1);
                }
                sessionStorage.setItem("isok3", "true");
              }
            }, 900);
          })
          .catch();
      } catch (e) {
        // 错误处理代码片段
        console.log(e);
        this.baocuo(e);
      }
    },
    getPlate(val) {
      console.log(val);
      this.addForm.carNo = val;
      this.pleshow = false;
    },
    onLoad() {
      const data = {
        id: this.id
      };

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      userModel
        .audit(data)
        .then(e => {
          loading.clear();
          this.addForm = e.data;
        })
        .catch(() => loading.clear());
    },

    getConfig() {
      let that = this;
      userModel
        .config({ url: location.href.split("#")[0], gid: this.shangjia.id })
        .then(res => {
          if (res.code == 200) {
            console.log(res);
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.appid, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名
              jsApiList: [
                "chooseImage",
                "getLocalImgData",
                "downloadImage",
                "uploadImage"
              ] // 必填，需要使用的JS接口列表
            });
          } else {
            this.$toast(res.data.msg);
          }
        });
    },
    //点击放大图片
    getImg(images) {
      ImagePreview([images]);
    },
    back() {
      if (this.zcisok == "true") {
        this.$router.push({
          path: "user"
        });
      } else {
        this.$router.go(-1);
      }
    }
  },
  created() {
    // this.getConfig();
    if (this.$route.query.denglu != undefined) {
      var url =
        this.$route.path + "?gid=" + this.$route.query.gid + "&zcisok=" + true;
      localStorage.setItem("beforeUrl", url);
      sessionStorage.setItem("gid", this.$route.query.gid);
      userModel
        .getGarageInfo({ gid: this.$route.query.gid })
        .then(e => {
          // loading.clear();
          this.shangjia = e.data;
          sessionStorage.setItem("appid", e.data.idd);
          sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
          // 正式
          const redirect_uri = encodeURIComponent(
            "https://shopping.car-posthouse.cn/auths"
          );
          window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        })
        .catch(() => loading.clear());
    }
    this.auth = utils.getUrlKey("auth") ? utils.getUrlKey("auth") : "0";
    this.zcisok = utils.getUrlKey("zcisok");
    this.id = utils.getUrlKey("id");
    this.chexin = utils.getUrlKey("chexin");
    var chexin = JSON.parse(sessionStorage.getItem("chexin"));
    if (chexin == null || chexin == "null") {
      //判断
    } else {
      this.addForm.phone = chexin.phone;
      this.phisok = true;
    }

    if (this.auth != 0) {
      this.onLoad();
    }
  },
  mounted() {
    var _this = this;
    document.body.addEventListener("focusout", () => {
      // 回到原点  若觉得一瞬间回到底部不够炫酷，那自己可以自定义缓慢回弹效果， 可用css 、贝塞尔曲线、js的 requestAnimationFrame  等等 方法 实现体验性更好的回弹效果
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      _this.ruanjianpan = true;
    });
  },

  activated() {
    this.carshow = false;
    var che = JSON.parse(sessionStorage.getItem("che"));
    // this.chexin
    if (che != null) {
      this.carBrandLogo = che.carBrandLogo;
      this.chexin = che.carBrand + "-" + che.carModel;
    }

    console.log(che);
    sessionStorage.removeItem("che");
  }
};
</script>
<style>
.dd .van-field__right-icon {
  color: #07c160;
}
.cc .van-field__right-icon {
  color: red;
}
.van-dialog__header {
  font-weight: bold;
}
.van-field__label {
  width: 85px;
}
</style>
<style lang="less" scoped>
.van-popup {
  background: none;
  width: 100%;
}
.gbiquan {
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  margin-right: 7%;
  margin-top: 15px;
  font-size: 26px;
}
.cgenduo {
  line-height: 40px;
  font-size: 14px;
  color: #07c160;
  text-align: right;
  padding: 0 12px;
  border-bottom: 1px solid #ebedf0;
}
.hhh1 {
  font-size: 18px;
  padding-top: 20px;
}
.mianfei {
  width: 80px;
  vertical-align: middle;
}
.kaixin_a {
  height: 180px;
  width: 80%;
  background: #fff;
  margin: auto;
  text-align: center;
  position: relative;
}
.xx {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
  margin-right: 8px;
  margin-top: 8px;
}
.shouxia {
  width: 120px;
  margin-top: 30px;
}
.xixi {
  font-size: 18px;
  margin-top: 25px;
}
.dai {
  width: 85%;
  display: block;
  margin: auto;
}
.dd {
  color: #07c160;
  font-size: 16px;
  vertical-align: middle;
}
.tishi {
  text-indent: 2em;
  padding: 8px 15px;
  font-weight: 500;
  font-size: 16px;
  color: #333;
}
.img_dw {
  width: 70%;
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -35%;
  margin-top: 30px;
}
.shang {
  // width: 266px;
  width: 85%;
  // height: 100px;
}
.biao_ti {
  height: 46px;
  background: #fff;
  width: 100%;
  font-size: 16px;

  line-height: 46px;
  text-align: center;
  color: #000;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.cheimgs {
  width: 100%;
  height: 100%;
}
.zidong {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #df3d48;
  letter-spacing: 0;
  text-align: center;
  margin-top: 5px;
}
.fanhui {
  line-height: 46px;
  padding: 0 10px;
  position: absolute;
  left: 0;
  top: 0;
}
.pp {
  position: absolute;
  left: 0;
}
// 底部
.bott {
  height: 55px;
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  padding: 0 12px;
  z-index: 999;
}
.bott_cd {
  margin-top: 10px;
  font-size: 14px;
  background: #df3d48;
  border: 1px solid #df3d48;
  color: #fff;
  text-align: center;
  font-weight: bold;
  width: 100%;
  height: 40px;
}
.cheshibie {
  position: absolute;
  left: 0;
  top: 0;
  color: red;
  font-weight: bold;
}
.bott_c {
  margin-top: 10px;
  font-size: 14px;
  background: #07c160;
  color: #fff;
  text-align: center;
  font-weight: bold;
  padding: 7px 18px;
  border-radius: 19px;
}
.pai_list:nth-of-type(n + 2) {
  margin-top: 15px;
}
.tupian {
  // border: 1px solid #cac6c6;
  height: 200px;
  width: 96%;
  position: relative;
  left: 50%;
  margin-left: -48%;
  background: url(../../assets/img/xszbeijin.png) no-repeat;
  background-size: 100% 100%;
}
.van-uploader {
  z-index: 99;
  color: #666;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -74px;
  margin-left: -133px;
}
.shanchu {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  z-index: 999;
}
.van-icon-plus {
  color: #cac6c6;
  font-size: 60px;
  width: 70px;
  height: 70px;
  text-align: center;
  line-height: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -35px;
  margin-top: -35px;
}
.manual {
  position: fixed;
  bottom: 0;
  left: 0;
  margin-bottom: 295px;
  z-index: 999;
  font-size: 16px;
  padding-left: 20px;
  line-height: 25px;
  color: blue;
}
</style>
